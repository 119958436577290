import React from 'react';

const Backend = () => (
  <div className="skills_content">
    <h3 className="skills_title">Backend</h3>

    <div className="skills_box">
      <div className="skills_group">
        <div className="skills_data">
          <i className="bx bx-badge-check" />
          <div>
            <h3 className="skills_name">Ruby</h3>
          </div>
        </div>
      </div>

      <div className="skills_group">
        <div className="skills_data">
          <i className="bx bx-badge-check" />
          <div>
            <h3 className="skills_name">Ruby on Rails</h3>
          </div>
        </div>
      </div>

      <div className="skills_group">
        <div className="skills_data">
          <i className="bx bx-badge-check" />
          <div>
            <h3 className="skills_name">SQL</h3>
          </div>
        </div>
      </div>

      <div className="skills_group">
        <div className="skills_data">
          <i className="bx bx-badge-check" />
          <div>
            <h3 className="skills_name">NodeJs</h3>
          </div>
        </div>
      </div>

    </div>
  </div>
);

export default Backend;
